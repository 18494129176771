import React, { useCallback, useEffect, useMemo } from "react";
import { inject, observer } from "mobx-react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import PricingStore from "../_stores/pricing/PricingStore";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ModalCloseButton from "library/components/modal/modal-close-button";
import TailwindButton from "library/components/_tailwind/button";
import TailwindCard from "library/components/_tailwind/card";
import HelpTooltip from "library/components/_tailwind/help-tooltip";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import NodeChatStore from "../_stores/nodechat/NodeChatStore";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  broadcastStore?: BroadcastStore;
  pricingStore?: PricingStore;
  nodeChatStore?: NodeChatStore;
  languageStore?: LanguageStore;
}

const PrivateRequestCard: React.FunctionComponent<
  Props & WrappedComponentProps
> = ({ languageStore, pricingStore, broadcastStore, nodeChatStore }) => {
  const {
    setPrivateConsentOpen,
    privateShowRequestAskForLater,
    privateShowRequestAccept,
    privateShowRequestDeclineForSession,
    currentPrivateRequest,
    privateChatPrice,
    partyChatPrice,
    currentPrivateRequestRemainingSeconds: remainingSeconds,
  } = broadcastStore!;
  const { modelProducts } = pricingStore!;
  const { members, allMemberHash } = nodeChatStore!;
  const { intl } = languageStore!;

  const closeModal = useCallback(async () => {
    try {
      await privateShowRequestAskForLater();
    } finally {
      setPrivateConsentOpen(false);
    }
  }, []);

  const showLabel = useMemo(() => {
    return currentPrivateRequest?.show_type === "VERIFIER"
      ? "Verifier"
      : currentPrivateRequest?.show_type === "GROUP"
      ? "Party"
      : "Private";
  }, [currentPrivateRequest]);

  const price = useMemo(() => {
    if (currentPrivateRequest?.show_type === "GROUP") {
      return partyChatPrice ? partyChatPrice : modelProducts?.party_chat;
    } else {
      return privateChatPrice ? privateChatPrice : modelProducts?.private_chat;
    }
  }, [currentPrivateRequest, modelProducts]);

  useEffect(() => {
    const chatHandle =
      `${currentPrivateRequest?.member_username}@cams`.toLowerCase();
    const isMemberOnline = currentPrivateRequest
      ? !!allMemberHash[chatHandle]?.online
      : null;

    if (!isMemberOnline) {
      closeModal();
    }
  }, [currentPrivateRequest, members]);

  return (
    <TailwindCard
      backgroundColor={"bg-card-secondary-bg-color"}
      textColor={"text-main-color"}
      className={["BroadcastControl", "BroadcastControl__Private"]}>
      <ModalCloseButton closeModal={closeModal} />
      <div className={"BroadcastControl__Private-title"}>
        <p>
          {intl.formatMessage(
            {
              id: "free-to-enter-private.request.title",
              defaultMessage: "{showLabel} Show Request",
            },
            {
              showLabel: intl.formatMessage({
                id: `showTypes2.${showLabel.toLowerCase()}`,
                defaultMessage: showLabel,
              }),
            }
          )}
        </p>
      </div>
      {showLabel === "Verifier" ? (
        <>
          <div className={"BroadcastControl__Private-body"}>
            <p>
              {intl.formatMessage(
                {
                  id: "free-to-enter-private.request.member-verify",
                  defaultMessage: "{ member } wants to take you to verify",
                },
                {
                  member: currentPrivateRequest?.member_username,
                }
              )}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className={"BroadcastControl__Private-body"}>
            <p>
              {intl.formatMessage(
                {
                  id: "free-to-enter-private.request.member",
                  defaultMessage:
                    "{ member } wants to take you {showLabel} for",
                },
                {
                  member: currentPrivateRequest?.member_username,
                  showLabel: intl.formatMessage({
                    id: `showTypes2.${showLabel.toLowerCase()}`,
                    defaultMessage: showLabel.toLowerCase(),
                  }),
                }
              )}
            </p>
          </div>
          <div className={"BroadcastControl__Private-body"}>
            <p>
              {intl.formatMessage(
                {
                  id: "free-to-enter-private.request.price",
                  defaultMessage: "{ price } tokens / minute",
                },
                { price }
              )}
            </p>
          </div>
        </>
      )}

      <div className={"BroadcastControl__Private-button"}>
        <TailwindButton
          justifyContent={"justify-center"}
          rounded={false}
          borderWidth={["border-2"]}
          borderColor={"border-black"}
          backgroundColor={"bg-secondary-bg-color"}
          size={"normal"}
          onClick={privateShowRequestAccept}>
          {intl.formatMessage({
            id: "free-to-enter-private.request.button",
            defaultMessage: "Yes!",
          })}
        </TailwindButton>
      </div>
      <TailwindFlex
        width={"w-2/3"}
        alignItems='items-center'
        justifyContent='justify-between'
        className={["BroadcastControl__Private-links"]}>
        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-center'
          className={["BroadcastControl__Private-links-item"]}
          onClick={privateShowRequestAskForLater}>
          <TailwindTranslatedText
            textColor='text-main-color'
            descriptor={{
              id: "free-to-enter-private.ask",
              defaultMessage: "Ask later",
            }}
          />
        </TailwindFlex>
        <TailwindFlex
          alignItems='items-center'
          justifyContent='justify-center'
          className={["BroadcastControl__Private-links-item"]}>
          <TailwindTranslatedText
            textColor='text-main-color'
            descriptor={{
              id: "free-to-enter-private.decline",
              defaultMessage: "Decline",
            }}
            onClick={privateShowRequestDeclineForSession}
          />
          <HelpTooltip
            tooltipClassName='BroadcastControl__Private-tooltip-decline'
            tooltip={intl.formatMessage({
              id: "free-to-enter-private.decline-tooltip",
              defaultMessage:
                "This will prohibit the member from asking again for the remainder of the session.",
            })}></HelpTooltip>
        </TailwindFlex>
      </TailwindFlex>
      <div className={"BroadcastControl__Private-footer"}>
        <AccessTimeIcon />
        <p>
          {intl.formatMessage(
            {
              id: "free-to-enter-private.request.footer",
              defaultMessage: "Please respond within {second} seconds",
            },
            {
              second: remainingSeconds,
            }
          )}
        </p>
      </div>
    </TailwindCard>
  );
};

export default injectIntl(
  inject(
    "broadcastStore",
    "pricingStore",
    "nodeChatStore",
    "languageStore"
  )(observer(PrivateRequestCard))
);
